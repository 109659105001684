// npm
import React, { memo } from 'react'
import { Box, Container } from '@mui/material'

// components
import { theme } from '@molecules/sections/components/SectionImageAndText'

export interface ContentProps {
  heading: string
  text: string
  theme: theme
}

const Content = ({ heading, text, theme }: ContentProps) => {
  return (
    <Container maxWidth={false}>
      <Box
        px={{ xs: 0, md: 6, lg: 8, xl: 15 }}
        py={{ xs: 4, md: 8, lg: 11, xl: 18 }}
        {...theme}
      >
        <Box component="h2" typography="sectionHeading" mt={0}>
          {heading}
        </Box>
        <Box
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Box>
    </Container>
  )
}

export default memo(Content)
