// npm
import React, { memo } from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useTheme } from '@mui/material'
import loadable from '@loadable/component'

// components
import Content, { ContentProps } from '@molecules/sections/imageAndText/Content'
const FullImage = loadable(
  () => import('@molecules/sections/imageAndText/FullImage')
)
const Standard = loadable(
  () => import('@molecules/sections/imageAndText/Standard')
)

export type theme = {
  bgcolor: string
  color: string
}

export interface SectionImageAndTextProps extends ContentProps {
  image: IGatsbyImageData
  backgroundColor: Queries.DatoCmsColour
  altLayout: boolean
  fullImage: boolean
}

const SectionImageAndText = ({
  text,
  heading,
  image,
  altLayout,
  fullImage,
  backgroundColor,
}: SectionImageAndTextProps) => {
  const muiTheme = useTheme()
  let theme: theme
  switch (backgroundColor.muiId) {
    case 'secondary.main':
      theme = {
        bgcolor: backgroundColor.muiId,
        color: muiTheme.palette.common.white,
      }
      break
    default:
      theme = {
        bgcolor: backgroundColor.muiId,
        color: muiTheme.palette.text.primary,
      }

      break
  }

  const ContentBase = () => (
    <Content heading={heading} text={text} theme={theme} />
  )

  if (fullImage) {
    return (
      <FullImage image={image} theme={theme} altLayout={altLayout}>
        <ContentBase />
      </FullImage>
    )
  } else {
    return (
      <Standard image={image} theme={theme} altLayout={altLayout}>
        <ContentBase />
      </Standard>
    )
  }
}

export default memo(SectionImageAndText)
